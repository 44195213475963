// src/components/DynamicRedirect/DynamicRedirect.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DynamicRedirect = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const path = searchParams.get('k');
        const ref = searchParams.get('ref');
        
        if (path) {
            navigate(`/v1/portal/csutc/dashboard/${path}`, { replace: true, state: { ref } });
        } else {
            navigate('/not-found', { replace: true });
        }
    }, [location, navigate]);

    return null;
};

export default DynamicRedirect;

