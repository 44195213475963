// src/components/SearchResults/SearchResults.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Grid, Card, CardContent, CardMedia, Typography, CircularProgress, Alert } from '@mui/material';
import useResultClickHandler from '../../utils/resultClickHandler';
import { useTitle } from '../Contexts/TitleContext';

const SearchResults = () => {
    const location = useLocation();
    const handleResultClick = useResultClickHandler();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('k');
     const { setTitle } = useTitle();
    
      useEffect(() => {
         setTitle(`Search | Total Result: ${results.length}`);  
       });  
    

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
    
            try {
                const response = await axios.get(`/client/api/dashboard/search?query=${query}`);
                console.log('Response data:', response.data);  // Log the full response data
    
                if (Array.isArray(response.data.results)) {
                    console.log('Search results:', response.data.results);  // Log the results specifically
                    setResults(response.data.results);
                } else {
                    console.log('No results or invalid data structure');
                    setResults([]);
                }
            } catch (err) {
                console.error('Error fetching search results:', err);
                setError('Failed to fetch search results. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
    
        if (query) {
            fetchData();
        }
    }, [query]);
    

    if (loading) {
        return <CircularProgress style={{ display: 'block', margin: '20px auto' }} />;
    }

    if (error) {
        return <Alert severity="error" style={{ margin: '20px auto', maxWidth: '600px', textAlign: 'center' }}>
            {error}
        </Alert>;
    }

    if (results.length === 0) {
        return <Typography align="center" style={{ margin: '20px auto' }}>No Results Found</Typography>;
    }

    return (
        <div style={{ padding: '20px' }}>
            {/* Total Results Count */}
            <Typography variant="h5" style={{ marginBottom: '20px', textAlign: 'center' }}>
                {`Total Results: ${results.length}`}
            </Typography>

            {/* Results Grid */}
            <Grid container spacing={3}>
                {results.map((result) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={result.uuid}>
                        <Card
                            onClick={() => handleResultClick(result.typeLink, result.uuid)}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                height: '100%',
                            }}
                        >
                            {/* Circular Image */}
                            <CardMedia
                                component="img"
                                alt={result.type}
                                image={result.cfdImage}
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    marginRight: '15px',
                                }}
                            />
                            {/* Card Content */}
                            <CardContent style={{ flex: 1 }}>
                                <Typography variant="h6">{result.type}</Typography>
                                <Typography variant="body2" color="textSecondary">{result.cfdSKU}</Typography>
                                <Typography variant="body2" color="textSecondary">{result.company}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default SearchResults;
