// src/utils/resultClickHandler.js
import { useNavigate } from 'react-router-dom';

const useResultClickHandler = () => {
  const navigate = useNavigate();

  const handleResultClick = (typeLink, uuid) => {
    const searchUrl = `/v1/portal/csutc/dashboard/s?k=${typeLink}/details/${uuid}&ref=searchbar`;
    console.log('Navigating to URL:', searchUrl);
    navigate(searchUrl);
  };

  return handleResultClick;
};

export default useResultClickHandler;
